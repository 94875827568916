import { useState, useMemo, useEffect, useCallback } from 'react';
//import { Panel, OtcButton, CountrySelector, Dropdown, Table, Modal } from 'otc-ui-component-library'
import { useDropzone } from 'react-dropzone'
import { Button, Heading, Label } from '@auspost/postmaster-react'
import styles from './UploadBatch.module.scss';
import { Spinner } from '../components/Shared';
import ProductSelect from '../components/molecules/ProductSelect';
import portalClient from '../utils/PortalClient';
import moment from 'moment';

import { StrictButtonGroupProps } from 'semantic-ui-react';
//import FormData from 'form-data'
import { getMelbourneDate } from '../utils/utils';

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone({ setAcceptedFiles, containerKey, children, ...props }:{ setAcceptedFiles:any, containerKey:string, children:any}) {
    const {
        getRootProps,
        acceptedFiles,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: { 
        'text/plain': ['.txt'], 
        'application/xml': ['.xml'], 
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/octet-stream': ['.dat'] }, onDropAccepted: (fs) => setAcceptedFiles(fs), multiple: true });

    const style = useMemo(() => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isFocused, isDragAccept, isDragReject]);

    const files = acceptedFiles.map(file => (
        <div key={file.name}>
            {file.name} - {file.size} bytes
        </div>
    ));

    return (
        <div key={containerKey} className="container dragdroparea">
            <div {...getRootProps(style)}>
                <input {...getInputProps()} />
                {acceptedFiles?.length > 0 ? files : children}
            </div>
        </div>
    );
}


const UploadBatch = ({partner}:{partner:any}) => {
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [productFiles, setProductFiles] = useState<any[]>([]);
    const [acceptedFiles, setAcceptedFiles] = useState<any[number]|null>(null);
    const [selectedProduct, setSelectedProduct] = useState<any|null>(null);
    const [searching, setSearching] = useState(false);

    const loadUploads = useCallback(async () => {
        if(!partner?.PartnerID){
            console.log("Partner not loaded yet");
            return;
        }
        setSearching(true);
        try {
        let response = await portalClient.post(`/api/portal/dbproxy`,             
            {                 
                spName: "usp_UploadHistoryPartnerReport", 
                spParams: { 
                    intPartnerID: partner.PartnerID, dtDateStart: moment().subtract(2, 'years').format('YYYY-MM-DD'), dtDateEnd: moment().add(1, 'days').format('YYYY-MM-DD'),
                    strFileName: '', strUsername: '', intUploadStatus: -1}
            });
            console.log("uploads:", response);      
            if(response?.data[0]?.ErrorMessage === "No Rows Found")
                setUploadedFiles([]);
            else
                setUploadedFiles(response.data);
            setSearching(false);
            return;
        }
        catch(e:any) {
            console.error("Upload error:", e);
            setSearching(false);
            return;
        }
    }, [partner?.PartnerID]);

    const loadUploadsWhileUploading = useCallback(async (ProductName: string, FileName: StrictButtonGroupProps) => {
        if(!partner?.PartnerID){
            console.log("Partner not loaded yet");
            return;
        }
        if(!ProductName || !FileName) {
            console.log("ProductName or uuid not loaded yet");
            return;
        }

        const sleepMiliSeconds = 15000;
        const retryTimes = 120; //being within 30 minutes
        setSearching(true);
        try {
            for (let i = 1; i <= retryTimes; i++) {
                console.log(`It starts to process loadUploadsWhileUploading ${i} times for ProductName: ${ProductName}, FileName: ${FileName}`);
                let response = await portalClient.post(`/api/portal/dbproxy`,             
                {                 
                    spName: "usp_UploadHistoryPartnerReport", 
                    spParams: { 
                        intPartnerID: partner.PartnerID, dtDateStart: moment().subtract(2, 'years').format('YYYY-MM-DD'), dtDateEnd: moment().add(1, 'days').format('YYYY-MM-DD'),
                        strFileName: '', strUsername: '', intUploadStatus: -1}
                });
                console.log("uploads:", response);
    
                //will break anyway if it is the last retry time
                if(i === retryTimes){
                    setUploadedFiles(response.data);
                    break;
                }

                //will break when no rows found
                if(response?.data[0]?.ErrorMessage === "No Rows Found"){
                    setUploadedFiles([]);
                    break;
                }
                
                //will break when uploaded successfully
                const uploadProductNameFileName = response?.data?.filter((upload:any) => upload.ProductName === ProductName && upload.Filename === FileName) as any[];
                console.log("uploadProductNameFileName: ", uploadProductNameFileName);
                let uploadStatus = uploadProductNameFileName?.sort((a, b) => {
                    let bDate = new Date(b?.UploadDate?.toString() ?? "1900-01-01");
                    let aDate = new Date(a?.UploadDate?.toString() ?? "1900-01-01");
                    return bDate.getTime() - aDate.getTime();
                })?.shift()?.Status?.toString() ?? ""; 

                console.log("uploadStatus: ", uploadStatus);
                if(uploadStatus?.toLowerCase() === "successful" || uploadStatus?.toLowerCase()?.includes("fail")){
                    setUploadedFiles(response.data);
                    break;
                }

                //wait for 15 seconds
                console.log(`It will sleep for ${sleepMiliSeconds} miliseconds to retry ${i+1} times for ProductName: ${ProductName}, FileName: ${FileName}`);
                await new Promise<void>((resolve) => setTimeout(resolve, sleepMiliSeconds));
            }

            setSearching(false);
            return;
        }
        catch(e:any) {
            console.error("Upload error:", e);

            setSearching(false);
            return;
        }
    }, [partner?.PartnerID]);

    const postFile = async (url:string, fields:any[], file:File) => {
            const data = new FormData();
            Object.entries(fields).forEach(([field, value]) => {
                data.append(field, value);
            });
            data.append("file", file);
            console.log("file", `name: ${file?.name ?? "not clear"} , size: ${file?.size ?? "not clear"}`);
            let resp = await fetch(url, {
                method: 'POST',
                body: data
              });
            if(!resp.ok) {
                const text = await resp.text();
                throw new Error(text);
            }
            return await resp.text();
    }

    const uploadFile = async (newFile:any) => {              
        console.log("uploadFile: ", newFile);
        try {
            // upload file to S3 bucket
            let presignedResp = await portalClient.get(`/api/portal/presigneduploadurl/${partner.PartnerID}/${selectedProduct.ProductID}/${newFile.file?.name}`);
            console.log("presignedResp:", presignedResp);
            let preSignData = presignedResp.data;
            let uploadResp = await postFile(preSignData.url, preSignData.fields, newFile.file);
            console.log("uploadResp:", uploadResp);
            // Notify APIs that upload is completed
            let response = await portalClient.post(`/api/portal/upload`,             
                {
                    intPartnerID: partner.PartnerID, intProductID: selectedProduct.ProductID, strServerName: preSignData.url, 
                    strNewFileName: preSignData.newFileName, strFileName: preSignData.fileName, strUniqueID: preSignData.uuid
                });
            console.log("POST api/portal/upload response:", response);      
            return {...newFile, uuid: preSignData.uuid};
        }
        catch(e:any) {
            console.log("Error uploading file", e);
            alert("error uploading file!");
            throw new Error("Error uploading");
        } 
    }

    /*const processUploadStep = async (newFile:any, action:string, feedback:string) => {              
        console.log("processUploadStep: ", newFile);
        try {
            // Call /upload/:id/process
            let response = await portalClient.post(`/api/portal/upload/${newFile.uuid}/${action}`);
            console.log(`upload->${action} response:`, response);
            return newFile;
        }
        catch(e:any) {
            console.log(`Error ${feedback} file`, e);
            alert(`error ${feedback} file!`);
            throw new Error(`Error ${feedback}`);
        }
    }*/

    useEffect(() => {          
        loadUploads();
    }, [loadUploads]);

    useEffect(() => {          
        setProductFiles([]);
        let productFiles = uploadedFiles?.filter(u => u.ProductName === selectedProduct?.ProductName);
        console.log("product files", productFiles);
        setProductFiles(productFiles);
    }, [uploadedFiles, selectedProduct?.ProductName]);

    const doUpload = async () => {
        if((acceptedFiles?.length || 0) <= 0)
        {
            alert("Please select at least one voucher file");
            return;
        }
        let newFile:any|null = null;
        let id = 1000;
        for(let f in acceptedFiles) {
            let file = acceptedFiles[f];
            //console.log('doUpload->current,new', uploadedFiles, file);
            // let date = new Date();
            // date.setHours(date.getHours() + 10);
            let date = getMelbourneDate();
            let initialFiles = [...uploadedFiles];
            newFile = { UploadDate: date, ProductName: selectedProduct?.ProductName, file: file, Filename: file.name, Status: "Uploading...", id: id++, uuid: null };            
            try {
                setUploadedFiles([newFile, ...initialFiles]);
                newFile = await uploadFile(newFile);            
                setUploadedFiles([{...newFile, Status: "Queuing..."}, ...initialFiles]);
                /*newFile = await processUploadStep(newFile, "process", "processing");
                setUploadedFiles([{...newFile, Status: "Inserting to DB..."}, ...initialFiles]);                
                newFile = await processUploadStep(newFile, "insert", "inserting");
                setUploadedFiles([{...newFile, Status: "Validating..."}, ...initialFiles]);
                newFile = await processUploadStep(newFile, "validate", "validating");*/
                //await loadUploads();
                await loadUploadsWhileUploading(newFile?.ProductName, newFile?.Filename);
            }
            catch(e:any) {
                setUploadedFiles([{...newFile, Status: e.toString()}, ...initialFiles]);
            }
        }
    };

    return (
        <>
            <div className={`main container ${styles.uploadBatch}`}>
                {/*<Heading level="h1">Voucher Batch Upload</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Voucher File Upload
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doUpload}>Upload File</Button>
                            </div>
                        </div>
                    </div>
                    <div className='table-content'>
                        <div className="flex-container row fields">
                            <ProductSelect className="flex-cell-6" setProduct={setSelectedProduct} partner={partner} />
                            <div className="flex-cell-6">
                                <Label>Voucher file(s)</Label>
                                <StyledDropzone containerKey="dropzone" setAcceptedFiles={setAcceptedFiles}>
                                    <p>Drag 'n' drop voucher batch files here, or click to select file</p>
                                </StyledDropzone>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {productFiles?.length > 0 ?
                    <div className="table-container">
                        <div className="header-container">
                            <div className="header">
                                <Heading level="h3">
                                    Processed Files
                                </Heading>
                            </div>
                        </div>
                        <div className="table-content">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr className="row header"><th>Date Uploaded</th><th>Product</th><th>Uploaded by</th><th>Filename</th><th>Status</th></tr>
                                    {productFiles.sort((a, b) => moment(b.UploadDate).diff(moment(a.UploadDate))).map(i =>
                                        <tr key={i.UploadDate} className="row"><td>{moment(i.UploadDate).format('YYYY-MM-DD HH:mm')}</td><td>{i.ProductName}</td><td>{i.Username}</td><td>{i.Filename}</td>
                                        <td>{(i?.Status?.includes("...")) && <Spinner />}{i.Status}</td></tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div> :
                    <>
                        {searching && <Spinner />}
                        {(productFiles?.length === 0 && !searching) && <div className="table-container">
                        <div className="header-container">
                            <div className="header">
                                <Heading level="h4">
                                    No recently processed files found
                                </Heading>
                            </div>
                        </div>                      
                    </div>}</> }
            </div></>
    );
}

export default UploadBatch;
